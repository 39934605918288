// import { NavBar } from '@/components';
import React from 'react';

export default function App() {
  return (
    <div className="bg-cover bg-center bg-background-content h-screen">
      {/* <NavBar /> */}
      {/* <Home /> */}
      {/* <Content title="Home">
        <div>Test</div>
      </Content> */}
    </div>
  );
}
